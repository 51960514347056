// eslint-disable-next-line max-len
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import { ChessField } from './ChessField.tsx';
import { ChessGame, ChessPieces, GlobalMove, team } from '../util/chess/Chess.ts';
import { StompHandler } from '../db/StompHandler.ts';
import { SESSION_ID } from '../config/SessionConfig.ts';
import * as uuid from 'uuid';
import { CORE_URL, STOMP_STILL_ALIVE, TMP_GAME_ID } from '../config/NetworkingConfig.ts';
import { openGameOverDialog } from './GameOverDialog.tsx';
import { Dialog } from './dialogv2/DialogManger.tsx';
import { openPromoteDialog } from './PromoteDialog.tsx';

export interface ChessBoardRefHandle {
  reloadGame: () => void;
}
export interface ChessBoardProps {
  size: number;
}

export const ChessBoard = forwardRef<ChessBoardRefHandle, ChessBoardProps>(
  (props: ChessBoardProps, ref): React.JSX.Element => {
    const [selected, setSelected] = useState<number | null>(null);
    const [marked, setMarked] = useState<Set<number>>(new Set());
    const [apiMarked, setApiMarked] = useState<Set<number>>(new Set());
    const [data, setData] = useState<ChessPieces[]>(ChessGame.getInstance().getChessBoard());
    const [selectedKing, setSelectedKing] = useState<team>(null);

    const loadFromRemote = () => {
      fetch(`${CORE_URL}/game/${TMP_GAME_ID}`)
        .then((response) => response.json())
        .then((jsonResult) => {
          ChessGame.getInstance().fromRemote(jsonResult);
          setData(ChessGame.getInstance().getChessBoard());
        })
        .catch((err) => {
          console.error('error on retreiving initial game data', err);
        });
    };

    const reloadGame = useCallback(() => {
      fetch(`${CORE_URL}/game/create`, { method: 'GET' })
        .then((response) => response.text())
        .then((_ignored) => loadFromRemote())
        .catch((error) => console.error('error resetting game', error));
    }, []);


    useImperativeHandle(ref, () => ({
      reloadGame,
    }));

    // find better solution
    useEffect(() => {
      const result = ChessGame.getInstance().isGameOver();
      if (result != null) {
        openGameOverDialog({ winner: result, restartGame: reloadGame });
      }
    }, [reloadGame, data]);

    useEffect(() => {
    // should get game from api if there is none
      loadFromRemote();
    }, []);

    useEffect(() => {
      const newApiMarked: Set<number> = new Set();
      if (selected != null) {
        fetch(`${CORE_URL}/game/1_GAME_1/${selected}/moves`, { method: 'GET' })
          .then((response) => {
            return response.json();
          })
          .then((result: any[]) => {
            for (let i = 0; i < result.length; i += 1) {
              newApiMarked.add(result[i].end);
            }
          })
          .catch((err) => {
            console.error(
              'could not fetch data from get:',
              `${CORE_URL}/system`,
              err
            );
          }).finally(() => {
            setApiMarked(newApiMarked);
          });
      } else {
        setApiMarked(newApiMarked);
      }
    }, [selected]);

    const getFieldSize = (value: number): number => {
      return Math.floor(value / 8);
    };

    const [fieldSize, setFieldSize] = useState<number>(getFieldSize(props.size));

    const updateKey = useRef<string>(uuid.v4());

    const update = (updateVal: string) => {
      try {
        const parsed: GlobalMove = JSON.parse(updateVal);
        if (parsed.clientId != null && parsed.clientId !== SESSION_ID) {
          if (ChessGame.getInstance().getMoveCounter() !== (parsed.moveCounter)) {
            ChessGame.getInstance().loadFromRemote().then(() => {
              setData(ChessGame.getInstance().getChessBoard());
            }).catch((err) => {
              console.error('cant update board from remote', err);
            });
          }
          // simple check for validity
          if (parsed.start != null && parsed.end != null) {
            if (parsed.promoteTarget != null) {
              const result = ChessGame.getInstance().makeMove(
                parsed.start,
                parsed.end,
                parsed.promoteTarget,
                true
              );
              setData(result.data);
            } else {
              const result = ChessGame.getInstance().makeMove(
                parsed.start,
                parsed.end,
                undefined,
                true
              );
              setData(result.data);
            }
          }
        }
      } catch (err) {
        if (updateVal !== STOMP_STILL_ALIVE) {
          console.error('could not parse json probably', err);
        }
      }
    };

    useEffect(() => {
      StompHandler.getInstance().addUpdateNotify(updateKey.current, update);
    }, []);

    useEffect(() => {
      setFieldSize(getFieldSize(props.size));
    }, [props.size]);


    const selectField = useCallback((index: number) => {
      if (
        selected != null &&
      ChessGame.getInstance().getChessBoard()[selected] !== ChessPieces.EMPTY
      ) {
        if (
          (data[selected] === ChessPieces.W_PAWN ||
          data[selected] === ChessPieces.B_PAWN) &&
        (index < 8 || index >= 56) &&
          marked.has(index)
        ) {
          const makeSelectMove = (
            target: ChessPieces
          ): void => {
            const result = ChessGame.getInstance().makeMove(selected, index, target);
            setData(result.data);
            setSelectedKing(result.selectedTeam);
            setSelected(null);
            Dialog.close?.();
          };
          openPromoteDialog({ makeSelectMove });
        } else {
          const moveData = ChessGame.getInstance().makeMove(selected, index);
          setData(moveData.data);
          setSelectedKing(moveData.selectedTeam);
        }
      }
      setSelected(index);
    }, [selected, data, marked]);

    useEffect(() => {
      if (selected) {
        setMarked(
          ChessGame.getInstance().getPossibleDestinations(
            selected,
            data[selected]
          )
        );
      } else {
        setMarked(new Set());
      }
    }, [data, selected]);

    const renderFields = (): React.JSX.Element[] => {
      return data.map((item, i) => {
        const tS = selected === i;
        const tM = marked.has(i);
        const tA = apiMarked.has(i);
        return (
          <Pressable
            key={`field_${i}_${tS}_${tM}`}
            onPress={() => selectField(i)}>
            <ChessField
              size={fieldSize}
              index={i}
              piece={item}
              selected={tS}
              marked={tM}
              apiMarked={tA}
              active={selectedKing === null ||
                  selectedKing === ChessPieces.W_KING &&
                  ChessGame.getInstance().getMoveCounter() % 2 === 0}
            />
          </Pressable>
        );
      });
    };

    return (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: fieldSize * 8,
        }}>
        {renderFields()}
      </View>
    );
  });
