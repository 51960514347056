import React, { useCallback } from 'react';
import { Text } from 'react-native-paper';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { ChessGame, ChessPieces } from '../util/chess/Chess.ts';

export interface ChessFieldProps {
  size: number;
  index: number;
  piece: ChessPieces;
  active: boolean;
  selected?: boolean;
  marked?: boolean;
  apiMarked?: boolean;
}
export const ChessField: React.FC<ChessFieldProps> = React.memo(
  (props: ChessFieldProps): React.JSX.Element => {
    const getBackGroundColor = useCallback((): string => {
      const row = Math.floor(props.index / 8);
      if (row % 2 === 0) {
        if (props.index % 2 === 0) {
          // white
          return '#F2E1C3';
        }
        // black
        return '#C3A082';
      }
      if (props.index % 2 === 0) {
        // black
        return '#C3A082';
      }
      // white
      return '#F2E1C3';
    }, [props.index]);

    const fieldStyles = StyleSheet.create({
      base: {
        width: props.size,
        height: props.size,
        backgroundColor: getBackGroundColor(),
        alignItems: 'center',
        justifyContent: 'center',
      },
      marked: {
        borderWidth: 4,
        borderColor: 'white',
      },
      markedWrongTeam: {
        borderWidth: 4,
        borderColor: '#909090',
      },
      selected: {
        borderWidth: 4,
        borderColor: '#ffb400',
      },
      overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'blue',
        opacity: 0.15,
        zIndex: 1,
      },
      pieceText: {
        userSelect: 'none',
        fontSize: props.size * 0.8,
        color: props.piece > ChessPieces.W_PAWN ? 'black' : 'white',
        zIndex: 2,
      },
    });

    const getCorrectStyle = useCallback(() => {
      const resultStyle: ViewStyle[] = [fieldStyles.base];
      if (props.selected === true) {
        resultStyle.push(fieldStyles.selected);
      }
      if (props.marked === true) {
        if (props.active) {
          resultStyle.push(fieldStyles.marked);
        } else {
          resultStyle.push(fieldStyles.markedWrongTeam);
        }
      }
      return resultStyle;
    }, [
      fieldStyles.base,
      fieldStyles.selected,
      fieldStyles.marked,
      fieldStyles.markedWrongTeam,
      props.selected,
      props.marked,
      props.active,
    ]);

    return (
      <View style={getCorrectStyle()}>
        {props.apiMarked && <View style={fieldStyles.overlay} />}
        <Text style={fieldStyles.pieceText}>
          {ChessGame.getCorrectText(props.piece)}
        </Text>
      </View>
    );
  }
);
