import 'react-native-get-random-values';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { ThemeManager } from './util/ThemeManager.ts';
import { ChessBoard, ChessBoardRefHandle } from './components/ChessBoard.tsx';
import { Button, Text } from 'react-native-paper';
import { BUILD_VERSION, CORE_URL } from './config/NetworkingConfig.ts';

export default function App() {
  const { width, height } = useWindowDimensions();

  const [serverId, setServerId] = useState<number | null>(null);

  const boardRef = useRef<ChessBoardRefHandle>(null);

  const getWidth = (): number => {
    if (Math.min(width, height) > ThemeManager.WIDTH) {
      return ThemeManager.WIDTH;
    }
    return Math.max(Math.min(width, height), ThemeManager.MIN_WIDTH);
  };

  const padding: number = Math.min(width, height) > ThemeManager.WIDTH ? 16 : 8;


  const resetGame = () => {
    boardRef.current?.reloadGame();
  };


  useEffect(() => {
    fetch(`${CORE_URL}/system`, { method: 'GET' })
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        const data: { serverId: number } = JSON.parse(result);
        setServerId(data.serverId);
      })
      .catch((err) => {
        console.error(
          'could not fetch data from get:',
          `${CORE_URL}/system`,
          err
        );
      });
  }, []);

  return (
    <View>
      <View style={[styles.container, { height }, StyleSheet.absoluteFill]}>
        <View
          style={{
            width: getWidth(),
            height: getWidth(),
            backgroundColor: 'gray',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ChessBoard
            ref={boardRef}
            size={getWidth() - padding}
          />
        </View>
        <View style={{ width: getWidth(), margin: 8, flexDirection: 'row' }}>
          <Button
            mode="contained"
            onPress={resetGame}>
                        reset
          </Button>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text>Server Id: {serverId}</Text>
          <Text>Build Version: {BUILD_VERSION}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
