import { Client, Versions } from '@stomp/stompjs';
import { STOMP_URL } from '../config/NetworkingConfig.ts';

export class StompHandler {
  private static instance: StompHandler;

  private client: Client;

  private registerMap: Map<string, (update: string) => void>;

  private constructor() {
    this.registerMap = new Map();
    this.client = new Client({
      brokerURL: `${STOMP_URL}/chess-broker`,
      stompVersions: new Versions([Versions.V1_2]),
      debug: (msg) => console.debug('stomp', msg),
      logRawCommunication: true,
      onConnect: (frame) => {
        console.debug('stomp', 'game connected', frame);
        this.client.subscribe('/topic/game/update', (updatedGame) => {
          const registerUpdates = [...this.registerMap.values()];
          for (const register of registerUpdates) {
            register(updatedGame.body);
          }
        });
      },
      forceBinaryWSFrames: true,
      appendMissingNULLonIncoming: true,
      onDisconnect: (frame) => {
        console.debug('stomp', 'disconnected', frame);
      },
    });
    this.client.activate();
  }

  public isConnected(): boolean {
    return this.client.connected;
  }

  public static getInstance() {
    if (!StompHandler.instance) {
      StompHandler.instance = new StompHandler();
    }
    return StompHandler.instance;
  }

  public sendUpdate(stringData: string): void {
    if (!this.client.connected) {
      console.debug('client not yet connected');
      return;
    }
    this.client.publish({
      destination: '/app/move',
      body: stringData,
    });
  }

  public addUpdateNotify(key: string, update: any) {
    this.registerMap.set(key, update);
  }
  public removeUpdateNotify(key: string) {
    this.registerMap.delete(key);
  }
}
