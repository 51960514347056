import React from 'react';
import { View } from 'react-native';
import { ChessGame, ChessPieces, team } from '../util/chess/Chess.ts';
import { Dialog } from './dialogv2/DialogManger.tsx';
import { Button, Text } from 'react-native-paper';

export interface GameOverDialogProps {
    winner: team;
    restartGame: () => void;
}

const getCorrectText = (winner: team): string => {
  switch (winner) {
    case ChessPieces.W_KING:
      return 'White wins';
    case ChessPieces.B_KING:
      return 'Black wins';
    default:
      return 'Draw';
  }
};

export const GameOverDialog: React.FC<GameOverDialogProps> = React.memo(
  (props: GameOverDialogProps): React.JSX.Element => {
    return (
      <View>
        <Text style={{ textAlign: 'center' }}>{getCorrectText(props.winner)}</Text>
        {props.winner != null ?
          <Text style={{ fontSize: 100, textAlign: 'center' }}>
            {ChessGame.getCorrectText(props.winner)}
          </Text> : null}
        <Button
          mode="contained"
          onPress={() => {
            props.restartGame();
            Dialog.close?.();
          }}
          style={{ marginTop: 20 }}>RESTART</Button>
      </View>
    );
  }
);

GameOverDialog.displayName = 'GameOverDialog';
export const openGameOverDialog = (props: GameOverDialogProps): void => {
  Dialog.open?.({
    width: 300,
    height: 250,
    borderRadius: 12,
    backdropColor: 'rgba(0,0,0,0.6)',
  }, <GameOverDialog {...props}/>);
};
