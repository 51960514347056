// DialogContext.tsx
import React, { createContext, useContext, useState, useRef, ReactNode } from 'react';
import { View, StyleSheet, Animated, TouchableWithoutFeedback, Platform } from 'react-native';
import { Surface, useTheme } from 'react-native-paper';

// Define types
interface DialogProps {
    width?: number | string;
    height?: number | string;
    backdropColor?: string;
    borderRadius?: number;
    backgroundColor?: string;
    closeOnBackdropPress?: boolean;
    elevation?: number;
    style?: object;
}

interface DialogItem {
    id: string;
    props: DialogProps;
    content: ReactNode;
}

interface DialogContextType {
    open: (props?: DialogProps, content?: ReactNode) => string;
    close: (id?: string) => void;
    closeAll: () => void;
}

// Create context with null as default value
const DialogContext = createContext<DialogContextType | null>(null);

// Create provider component
export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogs, setDialogs] = useState<DialogItem[]>([]);
  const animationValues = useRef<Map<string, Animated.Value>>(new Map());
  const theme = useTheme();

  // Helper to get or create animation value for a dialog
  const getAnimationValue = (id: string): Animated.Value => {
    if (!animationValues.current.has(id)) {
      animationValues.current.set(id, new Animated.Value(0));
    }
    return animationValues.current.get(id) as Animated.Value;
  };

  // Open a new dialog
  const open = (props: DialogProps = {}, content?: ReactNode): string => {
    const id = Date.now().toString();
    const newDialog: DialogItem = { id, props, content };

    setDialogs((prevDialogs) => [...prevDialogs, newDialog]);

    // Animate dialog opening
    const animValue = getAnimationValue(id);

    // Using the recommended API instead of deprecated Animated.timing
    Animated.spring(animValue, {
      toValue: 1,
      useNativeDriver: true,
      damping: 20,
      stiffness: 90,
    }).start();

    return id;
  };

  // Close the last dialog or a specific one by ID
  const close = (id?: string): void => {
    if (!dialogs.length) {
      return;
    }

    // If no ID provided, close the last one
    const dialogToClose = id ? dialogs.find((d) => d.id === id) : dialogs[dialogs.length - 1];
    if (!dialogToClose) {
      return;
    }

    const animValue = getAnimationValue(dialogToClose.id);

    // Using the recommended API instead of deprecated Animated.timing
    Animated.spring(animValue, {
      toValue: 0,
      useNativeDriver: true,
      damping: 20,
      stiffness: 100,
    }).start(() => {
      setDialogs((prevDialogs) => prevDialogs.filter((d) => d.id !== dialogToClose.id));
      // Clean up animation value
      animationValues.current.delete(dialogToClose.id);
    });
  };

  // Close all dialogs
  const closeAll = (): void => {
    if (!dialogs.length) {
      return;
    }

    // Animate all dialogs closing
    const animations = dialogs.map((dialog) => {
      const animValue = getAnimationValue(dialog.id);
      return Animated.spring(animValue, {
        toValue: 0,
        useNativeDriver: true,
        damping: 20,
        stiffness: 100,
      });
    });

    Animated.parallel(animations).start(() => {
      setDialogs([]);
      animationValues.current.clear();
    });
  };

  // Render all dialogs
  const renderDialogs = (): ReactNode => {
    return dialogs.map((dialog) => {
      const {
        width = 300,
        height = 300,
        backdropColor = theme.dark ?
          'rgba(0,0,0,0.7)' :
          'rgba(0,0,0,0.5)', // Use theme-aware backdrop
        borderRadius = theme.roundness * 2,
        backgroundColor = theme.colors.surface,
        closeOnBackdropPress = true,
        elevation = 5,
        style = {},
      } = dialog.props;

      const animValue = getAnimationValue(dialog.id);

      return (
        <View key={dialog.id} style={styles.dialogContainer} >
          <TouchableWithoutFeedback
            onPress={() => closeOnBackdropPress && close(dialog.id)}
          >
            <Animated.View
              style={[
                styles.backdrop,
                { backgroundColor: backdropColor },
                { opacity: animValue },
              ]}
            />
          </TouchableWithoutFeedback>

          <Animated.View
            style={[
              styles.dialogWrapper,
              {
                opacity: animValue,
                transform: [
                  {
                    scale: animValue.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.9, 1],
                    }),
                  },
                ],
              },
            ]}
          >
            <Surface
              style={[
                styles.dialogContent,
                {
                  width,
                  height,
                  borderRadius,
                  backgroundColor,
                  elevation,
                },
                style,
              ]}
            >
              {dialog.content}
            </Surface>
          </Animated.View>
        </View>
      );
    });
  };

  return (
    <DialogContext.Provider value={{ open, close, closeAll }}>
      {children}
      {renderDialogs()}
    </DialogContext.Provider>
  );
};

// Hook to use the dialog context
export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

// Styles
const styles = StyleSheet.create({
  dialogContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    elevation: 10,
    ...(Platform.OS === 'web' ? {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      width: '100%',
    } as any : {}),
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
  },
  dialogWrapper: {
    position: Platform.OS === 'web' ? 'relative' : 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  dialogContent: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

// Define interfaces for the utility object
interface DialogStatic {
    open: ((props?: DialogProps, content?: ReactNode) => string) | null;
    close: ((id?: string) => void) | null;
    closeAll: (() => void) | null;
}

// Utility object for static access
export const Dialog: DialogStatic = {
  open: null,
  close: null,
  closeAll: null,
  // These methods will be initialized in the DialogInitializer component
};

// Component to initialize Dialog static methods
export const DialogInitializer: React.FC = () => {
  const dialogContext = useDialog();

  React.useEffect(() => {
    Dialog.open = dialogContext.open;
    Dialog.close = dialogContext.close;
    Dialog.closeAll = dialogContext.closeAll;

    return () => {
      Dialog.open = null;
      Dialog.close = null;
      Dialog.closeAll = null;
    };
  }, [dialogContext]);

  return null;
};
