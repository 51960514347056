import React from 'react';
import { Dialog } from './dialogv2/DialogManger.tsx';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { ChessGame, ChessPieces } from '../util/chess/Chess.ts';

export interface PromoteDialogProps {
    makeSelectMove: (target: ChessPieces) => void;
}

export const PromoteDialog: React.FC<PromoteDialogProps> = React.memo(
  (props: PromoteDialogProps): React.JSX.Element => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'center',
          gap: 16,
        }}>
        <Button
          mode={'contained'}
          onPress={() => props.makeSelectMove(ChessPieces.W_QUEEN)}>
          {ChessGame.getCorrectText(ChessPieces.W_QUEEN)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => props.makeSelectMove(ChessPieces.W_ROOK)}>
          {ChessGame.getCorrectText(ChessPieces.W_ROOK)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => props.makeSelectMove(ChessPieces.W_BISHOP)}>
          {ChessGame.getCorrectText(ChessPieces.W_BISHOP)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => props.makeSelectMove(ChessPieces.W_KNIGHT)}>
          {ChessGame.getCorrectText(ChessPieces.W_KNIGHT)}
        </Button>
      </View>
    );
  }
);

export const openPromoteDialog = (props: PromoteDialogProps): void => {
  Dialog.open?.({
    width: 300,
    height: 250,
    borderRadius: 12,
    backdropColor: 'rgba(0,0,0,0.6)',
  }, <PromoteDialog {...props} />);
};
