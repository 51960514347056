/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import { AppRegistry, Platform } from 'react-native';
import App from './App';
import { PaperProvider } from 'react-native-paper';
import { DialogInitializer, DialogProvider as DialogProviderV2 } from './components/dialogv2/DialogManger';
import { MAIN_THEME } from './util/ThemeManager';

function Main() {
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <PaperProvider theme={MAIN_THEME}>
      <DialogProviderV2>
        <React.Fragment>
          {Platform.OS === 'web' ? (
            <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
      `}</style>
          ) : null}
          <App/>
          <DialogInitializer/>
        </React.Fragment>
      </DialogProviderV2>
    </PaperProvider>
  );
}

AppRegistry.registerComponent('App', () => Main);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
