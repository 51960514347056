import { ChessSet } from './ChessSet.ts';
import { StompHandler } from '../../db/StompHandler.ts';
import { SESSION_ID } from '../../config/SessionConfig.ts';
import { CORE_URL, TMP_GAME_ID } from '../../config/NetworkingConfig.ts';

export type GlobalMove = {
  moveCounter: number;
  clientId: string;
  start: number;
  end: number;
  promoteTarget?: ChessPieces;
  gameId: string;
};

export enum ChessPieces {
  W_KING,
  W_QUEEN,
  W_ROOK,
  W_BISHOP,
  W_KNIGHT,
  W_PAWN,
  B_KING,
  B_QUEEN,
  B_ROOK,
  B_BISHOP,
  B_KNIGHT,
  B_PAWN,
  EMPTY,
}

export type team = ChessPieces.B_KING | ChessPieces.W_KING | null;

export class ChessGame {
  private static instance: ChessGame;

  private data: ChessPieces[] = [];

  private moveCounter: number = 0;

  private longWhiteRochadePossible: boolean = true;

  private shortWhiteRochadePossible: boolean = true;

  private longBlackRochadePossible: boolean = true;

  private shortBlackRochadePossible: boolean = true;

  private readonly gameId: string = TMP_GAME_ID;

  private selectedTeam: team = null;

  private constructor() {
    // temp
    const tmp: ChessPieces[] = [];
    for (let i = 0; i < 64; i += 1) {
      tmp.push(ChessPieces.EMPTY);
    }
    // build pawns white
    for (let i = 8; i < 16; i += 1) {
      tmp[i] = ChessPieces.W_PAWN;
    }
    // build row
    tmp[0] = ChessPieces.W_ROOK;
    tmp[1] = ChessPieces.W_KNIGHT;
    tmp[2] = ChessPieces.W_BISHOP;
    tmp[3] = ChessPieces.W_KING;
    tmp[4] = ChessPieces.W_QUEEN;
    tmp[5] = ChessPieces.W_BISHOP;
    tmp[6] = ChessPieces.W_KNIGHT;
    tmp[7] = ChessPieces.W_ROOK;
    // build pawns black
    for (let i = 48; i < 56; i += 1) {
      tmp[i] = ChessPieces.B_PAWN;
    }
    // build row
    tmp[56] = ChessPieces.B_ROOK;
    tmp[57] = ChessPieces.B_KNIGHT;
    tmp[58] = ChessPieces.B_BISHOP;
    tmp[59] = ChessPieces.B_KING;
    tmp[60] = ChessPieces.B_QUEEN;
    tmp[61] = ChessPieces.B_BISHOP;
    tmp[62] = ChessPieces.B_KNIGHT;
    tmp[63] = ChessPieces.B_ROOK;

    // test fields
    this.data = tmp;
  }

  public static getInstance(): ChessGame {
    if (!ChessGame.instance) {
      ChessGame.instance = new ChessGame();
    }
    return ChessGame.instance;
  }

  public getMoveCounter(): number {
    return this.moveCounter;
  }

  public getChessBoard(): ChessPieces[] {
    const copy = [...this.data];
    return copy;
  }

  public setChessBoard(newBoard: ChessPieces[]): void {
    // TODO verficiation? validation?
    // handle that someone does not use it by accident
    // only change if not equal
    if (!this.isEqual(newBoard)) {
      this.data = newBoard;
    }
  }

  public isEqual(compareBoard: ChessPieces[]): boolean {
    return JSON.stringify(this.data) === JSON.stringify(compareBoard);
  }

  public makeMove(
    start: number,
    end: number,
    promoteTarget?: ChessPieces,
    fromRemote?: boolean
  ): { data: ChessPieces[], selectedTeam: team, winner: team } {
    const current = this.data[start];
    if (current === ChessPieces.EMPTY) {
      return { data: this.data, selectedTeam: this.selectedTeam, winner: this.isGameOver() };
    }
    const possible = this.getPossibleDestinations(start, this.data[start]);
    if (!possible.has(end)) {
      return { data: this.data, selectedTeam: this.selectedTeam, winner: this.isGameOver() };
    }
    const moveData: GlobalMove = {
      moveCounter: this.moveCounter,
      clientId: SESSION_ID,
      start: start,
      end: end,
      promoteTarget: promoteTarget,
      gameId: this.gameId,
    };
    if (fromRemote !== true) {
      // also select team if not already done
      if (this.selectedTeam == null) {
        if (this.data[start] < ChessPieces.B_KING) {
          this.selectedTeam = ChessPieces.W_KING;
        } else {
          this.selectedTeam = ChessPieces.B_KING;
        }
      }
      if (this.selectedTeam == null ||
          ChessGame.getTeamFromPiece(this.data[start]) === this.selectedTeam
      ) {
        StompHandler.getInstance().sendUpdate(JSON.stringify(moveData));
      }
      // check if player selected oponent team
      if (ChessGame.getTeamFromPiece(this.data[start]) !== this.selectedTeam) {
        return { data: this.data, selectedTeam: this.selectedTeam, winner: this.isGameOver() };
      }
    }
    this.moveCounter += 1;
    const newBoard: ChessPieces[] = [...this.data];
    if (
      (this.data[start] === ChessPieces.W_PAWN ||
        this.data[start] === ChessPieces.B_PAWN) &&
      (end < 8 || end >= 56) &&
      promoteTarget != null
    ) {
      if (this.data[start] === ChessPieces.W_PAWN) {
        newBoard[end] = promoteTarget;
      } else {
        // typescript back and forth with enums
        // +6 because of six different figures and input always white
        newBoard[end] =
          ChessPieces[
            ChessPieces[promoteTarget + 6] as keyof typeof ChessPieces
          ];
      }
    } else {
      newBoard[end] = this.data[start];
    }
    // rochade
    if (this.data[start] === ChessPieces.W_KING && Math.abs(start - end) > 1) {
      if (end === 1) {
        newBoard[0] = ChessPieces.EMPTY;
        newBoard[2] = ChessPieces.W_ROOK;
      }
      if (end === 5) {
        newBoard[7] = ChessPieces.EMPTY;
        newBoard[4] = ChessPieces.W_ROOK;
      }
    }
    if (this.data[start] === ChessPieces.B_KING && Math.abs(start - end) > 1) {
      if (end === 57) {
        newBoard[56] = ChessPieces.EMPTY;
        newBoard[58] = ChessPieces.B_ROOK;
      }
      if (end === 61) {
        newBoard[63] = ChessPieces.EMPTY;
        newBoard[60] = ChessPieces.B_ROOK;
      }
    }
    // check rochade possible
    if (
      this.data[start] === ChessPieces.B_ROOK ||
      this.data[start] === ChessPieces.W_ROOK
    ) {
      if (start === 0) {
        this.shortWhiteRochadePossible = false;
      }
      if (start === 7) {
        this.longWhiteRochadePossible = false;
      }
      if (start === 56) {
        this.shortBlackRochadePossible = false;
      }
      if (start === 63) {
        this.longBlackRochadePossible = false;
      }
    }
    if (this.data[start] === ChessPieces.W_KING && Math.abs(start - end) > 1) {
      this.longWhiteRochadePossible = false;
      this.shortWhiteRochadePossible = false;
    }
    if (this.data[start] === ChessPieces.B_KING && Math.abs(start - end) > 1) {
      this.longBlackRochadePossible = false;
      this.shortBlackRochadePossible = false;
    }
    newBoard[start] = ChessPieces.EMPTY;
    this.data = newBoard;
    return { data: this.data, selectedTeam: this.selectedTeam, winner: this.isGameOver() };
  }

  public getPossibleDestinations(
    index: number,
    piece: ChessPieces,
    customData: ChessPieces[] = this.data
  ): Set<number> {
    if (customData === this.data) {
      if (this.moveCounter % 2 === 0 && piece >= ChessPieces.B_KING) {
        return new ChessSet();
      }
      if (this.moveCounter % 2 !== 0 && piece < ChessPieces.B_KING) {
        return new ChessSet();
      }
    }
    switch (piece) {
      case ChessPieces.W_PAWN:
      case ChessPieces.B_PAWN:
        return this.getPawnMovement(index, piece, customData);
      case ChessPieces.B_BISHOP:
      case ChessPieces.W_BISHOP:
        return this.getBishopMovement(index, piece, customData);
      case ChessPieces.W_ROOK:
      case ChessPieces.B_ROOK:
        return this.getRookMovement(index, piece, customData);
      case ChessPieces.W_KNIGHT:
      case ChessPieces.B_KNIGHT:
        return this.getKnightMovement(index, piece, customData);
      case ChessPieces.B_QUEEN:
      case ChessPieces.W_QUEEN:
        return new ChessSet([
          ...this.getRookMovement(
            index,
            piece === ChessPieces.W_QUEEN ?
              ChessPieces.W_ROOK :
              ChessPieces.B_ROOK,
            customData
          ).values(),
          ...this.getBishopMovement(
            index,
            piece === ChessPieces.W_QUEEN ?
              ChessPieces.W_BISHOP :
              ChessPieces.B_BISHOP,
            customData
          ).values(),
        ]);
      case ChessPieces.B_KING:
      case ChessPieces.W_KING:
        return this.getKingMovement(index, piece, customData);
      default:
        return new Set();
    }
  }

  /**
   * PAWN movement ( white and black )
   * @param index position of pawn
   * @param piece figure to be moved ( white or black pawn )
   * @param data ChessFieldData to be checked against this moves
   * @private
   */
  private getPawnMovement(
    index: number,
    piece: ChessPieces,
    data: ChessPieces[]
  ): ChessSet {
    const answers: ChessSet = new Set();
    // PAWN
    if (piece === ChessPieces.B_PAWN) {
      if (data[index - 8] === ChessPieces.EMPTY) {
        answers.add(index - 8);
        if (data[index - 16] === ChessPieces.EMPTY) {
          // first move
          if (index > 47) {
            answers.add(index - 16);
          }
        }
      }
      // enemy
      if (data[index - 8 - 1] < ChessPieces.B_KING) {
        answers.add(index - 8 - 1);
      }
      if (data[index - 8 + 1] < ChessPieces.B_KING) {
        answers.add(index - 8 + 1);
      }
    }
    if (piece === ChessPieces.W_PAWN) {
      if (data[index + 8] === ChessPieces.EMPTY) {
        answers.add(index + 8);
        if (data[index + 16] === ChessPieces.EMPTY) {
          if (index < 16) {
            answers.add(index + 16);
          }
        }
      }
      // enemy
      if (
        index % 8 !== 0 &&
        data[index + 8 - 1] >= ChessPieces.B_KING &&
        data[index + 8 - 1] !== ChessPieces.EMPTY
      ) {
        answers.add(index + 8 - 1);
      }
      if (
        (index + 1) % 8 !== 0 &&
        data[index + 8 + 1] >= ChessPieces.B_KING &&
        data[index + 8 + 1] !== ChessPieces.EMPTY
      ) {
        answers.add(index + 8 + 1);
      }
    }
    // END PAWN
    return answers;
  }

  /**
   * BISHOP movement ( white and black )
   * @param index position of bishop
   * @param piece figure to be moved ( white or black bishop )
   * @private
   */
  private getBishopMovement(
    index: number,
    piece: ChessPieces,
    data: ChessPieces[]
  ): ChessSet {
    const answers: ChessSet = new ChessSet();
    for (let i = 1; i < 8; i += 1) {
      // top left
      const value = index - i * 8 - i;
      // not if figure is on target
      if (data[value] !== ChessPieces.EMPTY) {
        // unless its enemy ( beatable )
        if (piece >= ChessPieces.B_KING && data[value] < ChessPieces.B_KING) {
          answers.add(value);
        } else if (
          piece < ChessPieces.B_KING &&
          data[value] >= ChessPieces.B_KING
        ) {
          answers.add(value);
        }
        break;
      }
      // if on edge stop with iteration
      if (this.isEdge(value)) {
        if (index % 8 !== 0) {
          answers.add(value);
        }
        break;
      }
      answers.add(value);
    }
    for (let i = 1; i < 8; i += 1) {
      // top right
      const value = index - i * 8 + i;
      // not if figure is on target
      if (data[value] !== ChessPieces.EMPTY) {
        // unless its enemy ( beatable )
        if (piece >= ChessPieces.B_KING && data[value] < ChessPieces.B_KING) {
          if (!((index + 1) % 8 === 0)) {
            answers.add(value);
          }
        } else if (
          piece < ChessPieces.B_KING &&
          data[value] >= ChessPieces.B_KING
        ) {
          answers.add(value);
        }
        break;
      }
      // if on edge stop with iteration
      if (this.isEdge(value)) {
        if ((index + 1) % 8 !== 0) {
          answers.add(value);
        }
        break;
      }
      answers.add(value);
    }
    for (let i = 1; i < 8; i += 1) {
      // bottom right
      const value = index + i * 8 + i;
      // not if figure is on target
      if (data[value] !== ChessPieces.EMPTY) {
        // unless its enemy ( beatable )
        if (piece >= ChessPieces.B_KING && data[value] < ChessPieces.B_KING) {
          answers.add(value);
        } else if (
          piece < ChessPieces.B_KING &&
          data[value] >= ChessPieces.B_KING
        ) {
          answers.add(value);
        }
        break;
      }
      // if on edge stop with iteration
      if (this.isEdge(value)) {
        if ((index + 1) % 8 !== 0) {
          answers.add(value);
        }
        break;
      }
      answers.add(value);
    }
    for (let i = 1; i < 8; i += 1) {
      // bottom left
      const value = index + i * 8 - i;
      // not if figure is on target
      if (data[value] !== ChessPieces.EMPTY) {
        // unless its enemy ( beatable )
        if (piece >= ChessPieces.B_KING && data[value] < ChessPieces.B_KING) {
          answers.add(value);
        } else if (
          piece < ChessPieces.B_KING &&
          data[value] >= ChessPieces.B_KING
        ) {
          answers.add(value);
        }
        break;
      }
      // if on edge stop with iteration
      if (this.isEdge(value)) {
        if (index % 8 !== 0) {
          answers.add(value);
        }
        break;
      }
      answers.add(value);
    }
    return answers;
  }

  /**
   * Rook movement ( white and black )
   * @param index position of rook
   * @param piece figure to be moved ( white or black rook )
   * @private
   */
  private getRookMovement(
    index: number,
    piece: ChessPieces,
    data: ChessPieces[]
  ): ChessSet {
    const answers: ChessSet = new ChessSet();
    // up
    for (let i = 1; i < 8; i += 1) {
      const up = index - i * 8;
      if (piece === ChessPieces.B_ROOK && data[up] < ChessPieces.B_KING) {
        answers.add(up);
        break;
      }
      if (
        piece === ChessPieces.W_ROOK &&
        data[up] >= ChessPieces.B_KING &&
        data[up] !== ChessPieces.EMPTY
      ) {
        answers.add(up);
        break;
      }
      if (data[up] !== ChessPieces.EMPTY) {
        break;
      }
      answers.add(up);
    }
    // left
    for (let i = 1; i < 8; i += 1) {
      if (index % 8 === 0) {
        break;
      }
      const left = index - i;
      if (piece === ChessPieces.B_ROOK && data[left] < ChessPieces.B_KING) {
        answers.add(left);
        break;
      }
      if (
        piece === ChessPieces.W_ROOK &&
        data[left] !== ChessPieces.EMPTY &&
        data[left] >= ChessPieces.B_KING
      ) {
        answers.add(left);
        break;
      }
      if (data[left] !== ChessPieces.EMPTY) {
        break;
      }
      answers.add(left);
      if (this.isEdge(left)) {
        break;
      }
    }
    // right
    for (let i = 1; i < 8; i += 1) {
      if ((index + 1) % 8 === 0) {
        break;
      }
      const right = index + i;
      if (piece === ChessPieces.B_ROOK && data[right] < ChessPieces.B_KING) {
        answers.add(right);
        break;
      }
      if (
        piece === ChessPieces.W_ROOK &&
        data[right] >= ChessPieces.B_KING &&
        data[right] !== ChessPieces.EMPTY
      ) {
        answers.add(right);
        break;
      }
      if (data[right] !== ChessPieces.EMPTY) {
        break;
      }
      answers.add(right);
      if (this.isEdge(right)) {
        break;
      }
    }
    // down
    for (let i = 1; i < 8; i += 1) {
      const down = index + i * 8;
      if (piece === ChessPieces.B_ROOK && data[down] < ChessPieces.B_KING) {
        answers.add(down);
        break;
      }
      if (
        piece === ChessPieces.W_ROOK &&
        data[down] >= ChessPieces.B_KING &&
        data[down] !== ChessPieces.EMPTY
      ) {
        answers.add(down);
        break;
      }
      if (data[down] !== ChessPieces.EMPTY) {
        break;
      }
      answers.add(down);
    }
    return answers;
  }

  /**
   * Rook movement ( white and black )
   * @param index position of rook
   * @param piece figure to be moved ( white or black rook )
   * @private
   */
  private getKnightMovement(
    index: number,
    piece: ChessPieces,
    data: ChessPieces[]
  ): ChessSet {
    const answers: ChessSet = new ChessSet();
    // one step left
    if (index % 8 !== 0) {
      answers.add(index - 17);
      answers.add(index + 15);
      if ((index - 1) % 8 !== 0 && (index - 2) % 8 !== 0) {
        // two step left
        answers.add(index - 10);
        answers.add(index + 6);
      }
    }
    // one step right
    if ((index + 1) % 8 !== 0) {
      answers.add(index + 17);
      answers.add(index - 15);

      if ((index + 2) % 8 !== 0 && (index + 3) % 8 !== 0) {
        // two step right
        answers.add(index - 6);
        answers.add(index + 10);
      }
    }

    const allValues = [...answers.values()];
    for (const value of allValues) {
      const current = data[value];
      if (piece === ChessPieces.W_KNIGHT) {
        if (current < ChessPieces.B_KNIGHT) {
          answers.delete(value);
        }
      } else {
        if (current >= ChessPieces.B_KING && current !== ChessPieces.EMPTY) {
          answers.delete(value);
        }
      }
    }
    return answers;
  }

  /**
   * King movement ( white and black )
   * @param index position of King
   * @param piece figure to be moved ( white or black King )
   * @private
   */
  private getKingMovement(
    index: number,
    piece: ChessPieces,
    data: ChessPieces[]
  ): ChessSet {
    const answers: ChessSet = new ChessSet();
    // top
    if (index >= 8) {
      if (index % 8 !== 0) {
        answers.add(index - 8 - 1);
      }
      answers.add(index - 8);
      if ((index + 1) % 8 !== 0) {
        answers.add(index - 8 + 1);
      }
    }
    // middle
    answers.add(index - 1);
    answers.add(index + 1);
    // bottom
    if (index < 55) {
      if (index % 8 !== 0) {
        answers.add(index + 8 - 1);
      }
      answers.add(index + 8);
      if ((index + 1) % 8 !== 0) {
        answers.add(index + 8 + 1);
      }
    }
    if (piece === ChessPieces.B_KING) {
      for (const val of answers.values()) {
        if (data[val] > ChessPieces.W_PAWN && data[val] !== ChessPieces.EMPTY) {
          answers.delete(val);
        }
      }
    } else {
      for (const val of answers.values()) {
        if (data[val] <= ChessPieces.W_PAWN) {
          answers.delete(val);
        }
      }
    }
    // not on castling ( rochade ) check moves ( stop infinite recursion )
    if (this.data === data) {
      if (piece === ChessPieces.W_KING) {
        if (
          this.longWhiteRochadePossible &&
          data[4] === ChessPieces.EMPTY &&
          data[5] === ChessPieces.EMPTY &&
          data[6] === ChessPieces.EMPTY &&
          !this.isInDanger(3, ChessPieces.W_KING) &&
          !this.isInDanger(4, ChessPieces.W_KING) &&
          !this.isInDanger(5, ChessPieces.W_KING) &&
          !this.isInDanger(6, ChessPieces.W_KING)
        ) {
          answers.add(5);
        }
        if (
          this.shortWhiteRochadePossible &&
          data[1] === ChessPieces.EMPTY &&
          data[2] === ChessPieces.EMPTY &&
          !this.isInDanger(1, ChessPieces.W_KING) &&
          !this.isInDanger(2, ChessPieces.W_KING) &&
          !this.isInDanger(3, ChessPieces.W_KING)
        ) {
          answers.add(1);
        }
      }
      if (piece === ChessPieces.B_KING) {
        if (
          this.longBlackRochadePossible &&
          data[60] === ChessPieces.EMPTY &&
          data[61] === ChessPieces.EMPTY &&
          data[62] === ChessPieces.EMPTY &&
          !this.isInDanger(59, ChessPieces.B_KING) &&
          !this.isInDanger(60, ChessPieces.B_KING) &&
          !this.isInDanger(61, ChessPieces.B_KING) &&
          !this.isInDanger(62, ChessPieces.B_KING)
        ) {
          answers.add(61);
        }
        if (
          this.shortBlackRochadePossible &&
          data[57] === ChessPieces.EMPTY &&
          data[58] === ChessPieces.EMPTY &&
          !this.isInDanger(57, ChessPieces.B_KING) &&
          !this.isInDanger(58, ChessPieces.B_KING) &&
          !this.isInDanger(59, ChessPieces.B_KING)
        ) {
          answers.add(57);
        }
      }
    }
    return answers;
  }

  /**
   * Detects if a position is on the edge of the board
   * @param index the position that will be tested
   * @private
   */
  private isEdge(index: number) {
    return index % 8 === 0 || (index + 1) % 8 === 0;
  }

  /**
   * Checks if a certain position could be beaten bei the other team
   * @param index
   * @param piece
   * @private
   */
  private isInDanger(index: number, piece: ChessPieces): boolean {
    // todo maybe also needs to remove the original figure
    const tempDataField: ChessPieces[] = [...this.data];
    tempDataField[index] = piece;
    if (piece >= ChessPieces.B_KING && piece !== ChessPieces.EMPTY) {
      // black
      const allAvailablePositions = new ChessSet();
      for (let i = 0; i < this.data.length; i += 1) {
        const currentPiece = this.data[i];
        if (currentPiece <= ChessPieces.W_PAWN) {
          const currentMoves = this.getPossibleDestinations(i, this.data[i]);
          currentMoves.forEach((cMove) => {
            allAvailablePositions.add(cMove);
          });
        }
      }
      return allAvailablePositions.has(index);
    } else if (piece !== ChessPieces.EMPTY) {
      // white
      const allAvailablePositions = new ChessSet();
      for (let i = 0; i < this.data.length; i += 1) {
        const currentPiece = this.data[i];
        if (
          currentPiece >= ChessPieces.B_KING &&
          currentPiece !== ChessPieces.EMPTY
        ) {
          const currentMoves = this.getPossibleDestinations(i, this.data[i]);
          currentMoves.forEach((cMove) => {
            allAvailablePositions.add(cMove);
          });
        }
      }
      return allAvailablePositions.has(index);
    } else {
      throw Error(
        'invalid piece cant check danger without team ( empty piece was selected )'
      );
    }
  }

  public async loadFromRemote() : Promise<void> {
    fetch(`${CORE_URL}/game/${TMP_GAME_ID}`).then((response) => {
      return response.json();
    }).then((jsonResult) => {
      this.fromRemote(jsonResult);
    }).catch((err) => {
      console.error('error on retreiving initial game data', err);
    });
    try {
      const result = await fetch(`${CORE_URL}/game/${TMP_GAME_ID}`);
      const jsonResult = await result.json();
      this.fromRemote(jsonResult);
    } catch (err) {
      console.error('could not connect and load data from remote', err);
    }
  }

  public fromRemote(remoteData: any): boolean {
    if (remoteData.board.length !== 64) {
      console.error('board size from remote invalid', remoteData.board.length);
      return false;
    }
    const newRemoteBoard: ChessPieces[] = [];
    for (let i = 0; i < 64; i += 1) {
      const stringValue: string = remoteData.board[i];
      newRemoteBoard.push(ChessPieces[stringValue as keyof typeof ChessPieces]);
    }
    // this.prettyPrint(newRemoteBoard);
    this.data = newRemoteBoard;
    this.moveCounter = remoteData.moveCounter;
    this.longWhiteRochadePossible = remoteData.longWhiteRochadePossible;
    this.shortWhiteRochadePossible = remoteData.shortWhiteRochadePossible;
    this.longBlackRochadePossible = remoteData.longBlackRochadePossible;
    this.shortBlackRochadePossible = remoteData.shortBlackRochadePossible;
    return true;
  }

  private prettyPrint(board: ChessPieces[]): void {
    console.debug('  a b c d e f g h');
    console.debug('  ---------------');
    for (let row = 7; row >= 0; row--) {
      let line = `${row + 1}|`;
      for (let col = 0; col < 8; col++) {
        const piece = board[row * 8 + col];
        line += ChessGame.getCorrectText(piece) + ' ';
      }
      console.debug(line);
    }
  }

  public static getCorrectText = (piece: ChessPieces): string => {
    switch (piece) {
      case ChessPieces.W_KING:
        return '♔';
      case ChessPieces.W_QUEEN:
        return '♕';
      case ChessPieces.W_ROOK:
        return '♖';
      case ChessPieces.W_BISHOP:
        return '♗';
      case ChessPieces.W_KNIGHT:
        return '♘';
      case ChessPieces.W_PAWN:
        return '♙';
      case ChessPieces.B_KING:
        return '♚';
      case ChessPieces.B_QUEEN:
        return '♛';
      case ChessPieces.B_ROOK:
        return '♜';
      case ChessPieces.B_BISHOP:
        return '♝';
      case ChessPieces.B_KNIGHT:
        return '♞';
      case ChessPieces.B_PAWN:
        return '♟';
      default:
        return ' ';
    }
  };

  /**
   * this method will return B_KING if no white king is on the board
   *
   * this method will return W_KING if no black king is on the board
   *
   * this method will return null if both kings are on the board
   *
   */
  public isGameOver(): team {
    const whiteKing = this.data.indexOf(ChessPieces.W_KING);
    const blackKing = this.data.indexOf(ChessPieces.B_KING);
    if (whiteKing === -1 && blackKing === -1) {
      return null;
    }
    if (whiteKing === -1) {
      return ChessPieces.B_KING;
    }
    if (blackKing === -1) {
      return ChessPieces.W_KING;
    }
    return null;
  }

  public static getTeamFromPiece(piece: ChessPieces): team {
    if (piece < ChessPieces.B_KING && piece !== ChessPieces.EMPTY) {
      return ChessPieces.W_KING;
    }
    if (piece >= ChessPieces.B_KING && piece !== ChessPieces.EMPTY) {
      return ChessPieces.B_KING;
    }
    return null;
  }
}
